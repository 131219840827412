import { configureStore } from "@reduxjs/toolkit";
import headerInfoSlice from "./headerInfoSlice";
import api from "../Api";
import settingsSlice from "./settingsSlice";
import setupSlice from "./setupSlice";
import { apiRedux } from "./services/api";
import authSlice from "./authSlice";
import filtersSlice from "./filtersSlice";
import toastSlice from "./toastSlice";
import { rtkQueryErrorLogger } from "../utils/middleware";

export const store = configureStore({
  reducer: {
    headerInfo: headerInfoSlice,
    settings: settingsSlice,
    setup: setupSlice,
    auth: authSlice,
    filters: filtersSlice,
    toast: toastSlice,
    [apiRedux.reducerPath]: apiRedux.reducer,
  },
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      thunk: {
        extraArgument: api,
      },
    }).concat(apiRedux.middleware, rtkQueryErrorLogger);
  },
});

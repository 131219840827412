import { createSlice } from "@reduxjs/toolkit";
import { servicesApi } from "./services/services";

const initialState = {
  toasts: [],
};

const toastSlice = createSlice({
  name: "toast",
  initialState,
  reducers: {
    addToast: (state, action) => {
      const timestamp = Date.now();
      state.toasts = state.toasts.concat([
        { ...action.payload, id: timestamp },
      ]);
    },
    deleteToast: (state, action) => {
      state.toasts = state.toasts.filter(
        (toast) => toast.id !== action.payload
      );
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      servicesApi.endpoints.extendCalc.matchFulfilled,
      (state, action) => {
        console.log(action.payload);
      }
    );
  },
});

export const { addToast, deleteToast } = toastSlice.actions;

export default toastSlice.reducer;

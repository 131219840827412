import { isRejectedWithValue } from "@reduxjs/toolkit";
import { store } from "../storage/store";
import { addToast } from "../storage/toastSlice";

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (isRejectedWithValue(action)) {
    if (action.meta.arg.endpointName === "login") {
      return;
    } else if (action.meta.arg.endpointName === "extendCalc") {
      store.dispatch(
        addToast({
          title: "Уведомление",
          text: action.payload.data?.detail || "Произошла ошибка",
        })
      );
    } else {
      alert(JSON.stringify(action.payload.data));
    }
  }

  return next(action);
};

import { CssBaseline, Tab, Tabs, Box, Typography } from "@mui/material";
import React from "react";
import { Ctx } from "../App";
import PropTypes from "prop-types";
import { useTextQuery } from "../storage/services/services";

const Main = () => {
  const { cookie } = React.useContext(Ctx);
  const [page, setPage] = React.useState({});
  const { data } = useTextQuery();

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography component={"span"}>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  React.useEffect(() => {
    if (data) {
      setPage(data);
    }
  }, [cookie, data]);

  const [value, setValue] = React.useState(0);
  const handleChange = (_, newValue) => {
    setValue(newValue);
  };


  return (
    <CssBaseline>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
            variant="scrollable"
            scrollButtons
            allowScrollButtonsMobile
          >
            {
              Object.keys(page).map((title, i) => <Tab key={title+i} label={title} {...a11yProps(i)} />)
            }
          </Tabs>
        </Box>
        {Object.values(page).map((text,i) => <TabPanel key={i} value={value} index={i}>
          <div
            dangerouslySetInnerHTML={{ __html: text }}
          ></div>
        </TabPanel>)}
      </Box>
    </CssBaseline>
  );
};

export default Main;

import React, { useEffect, useState } from "react";
import s from "./style.module.css";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";
import { deleteToast } from "../../storage/toastSlice";

const Toast = ({ title, text, id }) => {
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleClick = () => {
    setOpen(false);
    setTimeout(() => dispatch(deleteToast(id)), 200);
  };

  useEffect(() => {
    setTimeout(() => setOpen(true), 0);
  }, []);

  return (
    <Box className={s.toastContainer + " " + (open ? s.open : s.hide)}>
      <IconButton
        sx={{ position: "absolute", top: 1, right: 1 }}
        onClick={handleClick}
      >
        <CloseIcon />
      </IconButton>
      <Typography textAlign={"center"} variant="h5">
        {title}
      </Typography>
      <Divider sx={{ mt: 2 }} />
      <Box sx={{ mt: 4 }}>
        <Typography textAlign={"center"} variant="subtitle1">
          {text}
        </Typography>
      </Box>
    </Box>
  );
};

export default Toast;

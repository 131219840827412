import React from "react";
import Toast from "./Toast";
import { useSelector } from "react-redux";

const ToastProvider = () => {
  const toasts = useSelector((state) => state.toast.toasts);

  return (
    <>
      {toasts.map((toast) => {
        return <Toast key={toast.id} {...toast} />;
      })}
    </>
  );
};

export default ToastProvider;
